import instagram from '../Components/Assets/instagram_icon.png'
import youtube from '../Components/Assets/youtube_icon.png'
import facebook from '../Components/Assets/facebook_icon.png'
import './CSS/Confirmation.css';
/*import { useEffect } from 'react';*/

const Confirmation = () => {
  /*useEffect(() => {
    const updateDatabase = async () => {
      try {
        // Retrieve cart details from localStorage or context
        const purchasedItems = JSON.parse(localStorage.getItem('purchasedItems'));
        if (!purchasedItems || purchasedItems.length === 0) {
          console.error('No purchased items found in localStorage.');
          return;
        }

        // Send purchased items to the backend
        const response = await fetch('https://backend.mariemadeit.com/update-availability', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ purchasedItems }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to update availability.');
        }

        const data = await response.json();
        console.log('Database update successful:', data);
      } catch (error) {
        console.error('Error updating database:', error.message);
      }
    };

    updateDatabase();
  }, []);*/

  return (
    <div className='confcontainer'>
      <div className="confirmation-page">
        <h1>Thank You for Your Purchase! Your receipt has been sent to your email for your records.</h1>
        <h2>Stay connected with us by following MarieMadeIt on social media for updates, new products, and Pattern tester calls.</h2>  
        <a className='social' href='https://www.instagram.com/mariemadeit__/?hl=en' _blank='target'><img src={instagram} alt='instagram-logo'/>Instagram</a>
        <a className='social' href='https://www.youtube.com/@MarieMadeIt96'><img src={youtube} alt='youtube-logo'/>Youtube</a>
        <a className='social' href='https://www.facebook.com/profile.php?id=100077528543304'><img src={facebook} alt='facebook-logo'/>Facebook</a>
        <p>Thank you once again for choosing MarieMadeIt!</p>
        <a className='returnbtn' href="/">Return Home</a>
      </div>
    </div>
  );
};

export default Confirmation;
