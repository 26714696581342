import React from 'react'
import Collection from '../Components/Collection/Collection'

const AllProducts = () => {
  return (
    <div>
      <Collection />
    </div>
  )
}

export default AllProducts;